:root {
  --black: #000000;
  --white: #ffffff;
  --blueberry: #006ab4;
  --blueberry-25: #f2f7fb;
  --blueberry-50: #e6f0f7;
  --blueberry-100: #cce1f0;
  --blueberry-200: #99c3e1;
  --blueberry-550: #005fa1;
  --blueberry-700: #003f6c;
  --british-green: #004e37;
  --british-green-25: #f2f5f4;
  --british-green-50: #e6edeb;
  --british-green-100: #ccdbd7;
  --british-green-200: #99b8af;
  --british-green-550: #004631;
  --british-green-700: #002e21;
  --fresh-green: #2ea836;
  --fresh-green-25: #f4faf4;
  --fresh-green-50: #eaf6eb;
  --fresh-green-100: #d5edd6;
  --fresh-green-200: #abdcae;
  --fresh-green-550: #299630;
  --fresh-green-700: #1b6420;
  --fresh-yellow: #fcbc00;
  --fresh-yellow-25: #fefbf2;
  --fresh-yellow-50: #fef8e6;
  --fresh-yellow-100: #fef1cc;
  --fresh-yellow-550: #e2a800;
  --fresh-yellow-200: #fde499;
  --fresh-yellow-700: #977000;
  --strawberry: #d70926;
  --strawberry-25: #fcf2f3;
  --strawberry-50: #fbe6e9;
  --strawberry-100: #f7cdd3;
  --strawberry-200: #ef9ca8;
  --strawberry-550: #c10822;
  --strawberry-700: #810516;
  --warm-grey: #686C6F;
  --warm-grey-25: #F7F7F7;
  --warm-grey-50: #F0F0F0;
  --warm-grey-100: #E0E1E2;
  --warm-grey-200: #C2C4C5;
  --warm-grey-550: #5D6063;
  --warm-grey-700: #3E4042;
}