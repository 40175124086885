.detailContainer {
  display: grid;
  grid-template-columns: 0 auto 50px;
  color: white;
}
@media (max-width: 599px) {
  .detailContainer {
    display: grid;
    grid-template-columns: 50px auto 50px;
  }
}
.rowContainer {
  display: flex;
  background-color: black;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}

.rowContainer > div {
  display: flex;
  flex-wrap: nowrap;
}

.describtionCol {
  margin-left: 2rem;
  font-size: small;
  font-weight: 100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rowClass {
  background-color:black;
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .rowContainer {
    background-color: black;
    margin-bottom: 0.5rem;
    align-items: center;
  }
  
  .rowContainer > div {
    flex-wrap: nowrap;
    display:block;
  }
  
  .rowClass {
    background-color:black;
    padding: 1.25rem;
  }

  .describtionCol {
    font-size: small;
    font-weight: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0;
  }
}

.addButton {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  margin-top: 0.5rem;
  align-items: center;
}

.mobileHeading{
  color: white;
  visibility: hidden;
}

@media (max-width: 599px) {
  .mobileHeading{
    color: white;
    visibility: visible;
  }
}