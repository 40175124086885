.homeMainSection {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
}

.homeMainSection .imgWrapper {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.homeMainSection .moneyImage {
  background-image: url(./../../assets/Images/landing.png);
  flex-grow: 1;
  max-height: 70vh;
  min-width: 30vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  margin-left: 5rem;
}

.homeMainSection .callToAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  align-self: center;
  margin-right: 5rem;
}

@media (max-width: 900px) {
  .homeMainSection .imgWrapper {
    display: none;
  }

  .homeMainSection {
    justify-content: center;
  }

  .homeMainSection .callToAction {
    margin-right: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
  color: white;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 2rem;
  color: white;
}

.discoverMainSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: whie;
}

.discoverMainSection .coversImage {
  width: 30vw;
  height: 70vh;
  max-width: 350px;
  align-self: center;
  background-image: url(./../../assets/Images/covers.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: 5rem;
}

.discoverMainSection .callToAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  align-self: center;
  margin-left: 5rem;
  color: white;
  border: 1px solid #f7f8fe;
}

.rowClass {
  border: 1px solid #f6f4f4;
  padding: 2rem;
}

.iconSection {
  display: flex;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .5rem;
  padding: .75rem;
  width: 100px;
  height: 100px;
  background-color: #202027;
  margin-bottom: 2rem;
  color: white;
}

.icon img {
  width: 40px;
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
  color: white;
}

@media (max-width: 800px) {
  .discoverMainSection {
    flex-direction: column;
    justify-content: center;
  }

  .discoverMainSection .coversImage {
    margin-top: 2rem;
    height: 30vh;
    width: auto;
  }
}

.active div {
  color: red;
  text-decoration-color: red;
  background-color: red;
}
