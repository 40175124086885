.tab {
  align-items: center;
  background: transparent;
  border: none;
  color: var(--warm-grey);
  cursor: pointer;
  display: inline-flex;
  font-family: MorrisonsAgenda;
  font-size: var(--font-size-body);
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 3.5rem;
  justify-content: center;
  line-height: var(--line-height-body);
  outline: none;
  padding: 0 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
}

.tab_active {
  color: var(--blueberry);
}

.tab_active:after {
  background: var(--blueberry);
  border-radius: 0.25rem;
  bottom: 0;
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  width: 100%;
  z-index: 1;
}