.modalFooter {
  background: var(--warm-grey-25);
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
}

.modalFooter > * {
  margin-left: 1rem;
}