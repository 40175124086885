.container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 10;
}

.container > ul {
  align-items: center;
  display: flex;
  list-style: none;
}

.container > ul > li {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  position: relative;
  z-index: 2;
  color: white;
}

.container a {
  color: white;
}

.link {
  border: none;
  color: var(--white);
  cursor: pointer;
  outline: none;
}
.hide {
  display: none;
}