 .discoverMainSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: whie;
}

.discoverMainSection .coversImage {
  width: 30vw;
  height: 70vh;
  max-width: 350px;
  align-self: center;
  background-image: url(./../../assets/Images/covers.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: 5rem;
}

.discoverMainSection .callToAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  align-self: center;
  margin-left: 5rem;
  color: white;
}

.iconSection {
  display: flex;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .5rem;
  padding: .75rem;
  width: 100px;
  height: 100px;
  background-color: #202027;
  margin-bottom: 2rem;
  color: white;
}

.icon img {
  width: 40px;
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
  color: white;
}

@media (max-width: 800px) {
  .discoverMainSection {
    flex-direction: column;
    justify-content: center;
  }

  .discoverMainSection .coversImage {
    margin-top: 2rem;
    height: 30vh;
    width: auto;
  }
}
