.select {
  background: var(--white);
  height: 3.5rem;
  outline: none;
  position: relative;
  user-select: none;
  width: 100%
}

.select_filter input {
  cursor: pointer;
}

.select_filter > svg {
  position: absolute;
  right: 1rem;
  top: 1rem;
  pointer-events: none;
}

.select_open.select_filter > svg {
  transform: rotate(180deg);
} 

.select__options {
  background: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-4-4);
  display: none;
  left: -1px;
  max-height: 12rem;
  overflow-y: scroll;
  padding: 0.25rem 0;
  position: absolute;
  top: calc(100% + 1px);
  width: calc(100% + 2px);
  z-index: 3;
  -webkit-overflow-scrolling: touch;
}

.select_open .select__options {
  display: block;
}