@import 'styles/mixins.scss';

.component {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    --max-master: 100%;
    --border: 1px solid var(--app-color-border, #dadada);
    @include app-when('sm') {
        --max-master: 500px;        
    }
}

@mixin scroll-children() {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
}

.master {
    width: 100%;
    max-width: var(--max-master);
    height: 100%;
    position: relative;
    @include scroll-children();
}

.detail {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    @include scroll-children();
}
