.joinMainSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  color: whie;
  padding: 2.5rem;
}

.joinMainSection .coversImage {
  width: 30vw;
  height: 70vh;
  max-width: 350px;
  align-self: center;
  background-image: url(./../../assets/Images/covers.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: 5rem;
}

.joinMainSection .callToAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  align-self: center;
  margin-left: 5rem;
  color: white;
}

.iconSection {
  display: flex;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .5rem;
  padding: .75rem;
  width: 100px;
  height: 100px;
  background-color: #202027;
  margin-bottom: 2rem;
  color: white;
}

.icon img {
  width: 40px;
}

.title {
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 1rem;
  margin-top: 0;
  color: white;
}

.colortitle {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
  color: red;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 2rem;
  color: white;
}

.displayrow {
  display: flex;
  flex-direction: row;
}

.joinform {
  background-color: #202027;
  width: 50vw;
  max-width: 50%;
  padding: 2rem 4rem;
  border-radius: 20px;
  color: white;
}

.inputgroup {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.inputgroup:last-child {
  margin-bottom: 0;
}

.inputgroup label {
  font-weight: bold;
  margin-bottom: .5rem;
}

.inputgroup input {
  outline: none;
  background: none;
  border: 1px solid #2E3852;
  font-size: 1em;
  padding: .5em;
  color: inherit;
  border-radius: 5px;
}

.inputgroup input:focus {
  border-color: #596da0;
}

@media (max-width: 800px) {
  .joinMainSection {
    flex-direction: column;
    justify-content: center;
  }

  .joinMainSection .coversImage {
    margin-top: 2rem;
    height: 30vh;
    width: auto;
  }
}

.container {
  background: var(--black);
  border-radius: 0.5rem;
  margin: 0 auto;
  max-width: 48rem;
  padding: 3rem;
  position: relative;
  top: -3rem;
}

.grid {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.actions {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 8.5rem;
  justify-content: end;
}
