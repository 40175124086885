@font-face {
  font-family: MorrisonsAgenda;
  src: url('./fonts/MorrisonsAgenda-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MorrisonsAgenda;
  src: url('./fonts/MorrisonsAgenda-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MorrisonsAgenda;
  src: url('./fonts/MorrisonsAgenda-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Oxygen Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxygenmono/v7/h0GsssGg9FxgDgCjLeAd7hjWx-6tPUUvkdU.woff2) format('woff2');
}

@font-face {
  font-family: 'Oxygen Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oxygenmono/v7/h0GsssGg9FxgDgCjLeAd7hjYx-6tPUUv.woff2) format('woff2');
}