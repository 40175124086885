.textField {
  position: relative;
}

.textField input, .textField textarea {
  border: 1px solid var(--warm-grey-50);
  border-radius: 0.25rem;
  color: var(--white);
  display: block;
  height: 3.5rem;
  padding: 1.625rem 1rem 0.375rem;
  resize: none;
  outline: none;
  width: 100%;
  background-color: #2F303A;
}

.textField textarea {
  height: 100%;
}

/* Hide Number Spinners */
/* Chrome */
.textField input::-webkit-outer-spin-button,
.textField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textField input[type=number] {
  -moz-appearance:textfield;
}

.textField > svg {
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.textField__label {
  color: var(--white);
	font-weight: normal;
	position: absolute;
  pointer-events: none;
  left: 1rem;
	top: 1rem;
}

.textField input:focus ~ .textField__label,
.textField textarea:focus ~ .textField__label,
.textField_hasValue .textField__label {
  line-height: var(--line-height-body-sm);
  font-size: var(--font-size-body-sm);
  color: var(--blueberry);
  top: 0.375rem;
}

.textField_hasValue.textField_disabled .textField__label {
  color: var(--warm-grey-550);
}

.textField_disabled input, .textField_disabled textarea {
  background: var(--warm-grey-25);
  color: var(--warm-grey);
  cursor: not-allowed;
}

.textField__helper {
  color: var(--warm-grey);
	font-weight: normal;
	position: absolute;
  pointer-events: none;
  right: 1rem;
	top: 1.625rem;
}