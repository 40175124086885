.react-datepicker-wrapper {
  position: relative;
  width: 100%;
}

.react-datepicker-wrapper input {
  cursor: pointer;
}

.react-datepicker__close-icon {
  padding: 0;
  right: 1rem;
}

.react-datepicker__close-icon::after {
  background: none;
  color: var(--body-200);
  content: "clear";
  font-size: var(--font-size-small-print);
  text-transform: uppercase;
}

.react-datepicker__input-container {
  display: block;
}