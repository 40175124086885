html {
  box-sizing: border-box;
  height: 100%;
}

body {
  background: var(--warm-grey-50);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box; 
}

img,
svg,
embed,
object,
video {
  display: block;
  max-width: 100%;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit; 
  text-decoration: inherit; 
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #2F303A;
}

.root__content {
  flex: 1;
}
