.container {
  bottom: 0;
  display: grid;
  padding: 1.5rem 1rem;
  /* position: sticky; */
  pointer-events: none;
  text-align: center;
  z-index: 3;
  background: black;
  color: white;
}

.container > ul {
  align-items: center;
  display: flex;
}

.container > ul > li {
  margin-left: 0.5rem;
  position: relative;
  z-index: 2;
}

.link {
  border: none;
  color: var(--white);
  cursor: pointer;
  padding: 0 1rem;
  outline: none;
}
