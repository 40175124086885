.modal {
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 78, 55, 0.92);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: 0.2s ease opacity;
  visibility: hidden;
  width: 100%;
  z-index: 10;
}

.modal_active {
  opacity: 1;
  visibility: visible;
}

.modal__content {
  background: var(--white);
  border-radius: 0.5rem;
  max-height: calc(100vh - 3rem);
  max-width: calc(100vw - 3rem);
  transition: 0.2s 0.1s ease all;
  transform: scale(0);
}

.modal_active .modal__content {
  transform: scale(1);
}