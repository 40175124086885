.selectToggle {
  border: 1px solid var(--warm-grey-50);
  border-radius: 0.25rem;
  cursor: pointer;
  height: 3.5rem;
  margin-bottom: 1rem;
  outline: none;
  padding: 1.625rem 1rem 0.375rem;
  position: relative;
  user-select: none;
  width: 100%
}

.selectToggle.selectToggle_disabled {
  background: var(--warm-grey-25);
  color: var(--warm-grey);
  cursor: not-allowed;
}

.selectToggle > svg {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.selectToggle_active > svg {
  transform: rotate(180deg);
}

.selectToggle__label {
  color: var(--warm-grey);
  font-weight: normal;
  left: 1rem;
  pointer-events: none;
	position: absolute;
	top: 1rem;
}

.selectToggle_hasValue .selectToggle__label {
  color: var(--blueberry);
  font-size: var(--font-size-body-sm);
  line-height: var(--line-height-body-sm);
  top: 0.375rem;
}

.selectToggle_hasValue.selectToggle_disabled .selectToggle__label {
  color: var(--warm-grey-550);
}

.selectToggle__value {
  height: 1.5rem;
  overflow: hidden;
  padding-right: 2rem;
  text-transform: capitalize;
  color: black;
}