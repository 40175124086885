.container {
  display: grid;
  grid-template-columns: 100px auto 100px;
  margin-top: 2rem;
}
.homeMainSection {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
}

.homeMainSection .imgWrapper {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.homeMainSection .moneyImage {
  background-image: url(./../../assets/Images/landing.png);
  flex-grow: 1;
  max-height: 70vh;
  min-width: 30vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  margin-left: 5rem;
}

.homeMainSection .callToAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
}

.rowContainer {
  position: relative;
  margin-bottom: 0.5rem;
}

@media (max-width: 900px) {
  .homeMainSection .imgWrapper {
    display: none;
  }

  .homeMainSection {
    justify-content: center;
  }

  .homeMainSection .callToAction {
    margin-right: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 2rem;
}

.discoverMainSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  color: whie;
  grid-column: 2/3;
}

.discoverMainSection .coversImage {
  width: 30vw;
  height: 70vh;
  max-width: 350px;
  align-self: center;
  background-image: url(./../../assets/Images/covers.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: 5rem;
}

.discoverMainSection .callToAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  position:relative;
}

.discoverMainSection .callToAction > div:nth-child(2) {
  width: 100%
}

.cellClass {
  display: grid;
  width: 85%;
  grid-template-columns: 1fr 2fr 0.5fr;
  grid-gap: 1rem;
}

.groupedCellClass{
  display: grid;
  width: 85%;
  grid-template-columns: 7fr 0.5fr 0.5fr;
  grid-gap: 1rem;
}

.cellGroupStyle{
  display: flex;
  flex-direction: column;
}

.rowClass {
  background-color:black;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
}

.groupedRowClass {
  background-color: var(--blueberry);
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
}

.groupedRowClass > div > div > div:nth-child(1) {
  font-size: small;
  font-weight: 100;
}

.rowClass > div > div > div:nth-child(2) {
  font-size: small;
  font-weight: 100;
}

.iconSection {
  display: flex;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .5rem;
  padding: .75rem;
  width: 100px;
  height: 100px;
  background-color: #202027;
  margin-bottom: 2rem;
}

.icon img {
  width: 40px;
}

.title {
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
}

@media (max-width: 800px) {
  .container {
    display: grid;
    grid-template-columns: 30px auto 30px;
    margin-top: 2rem;
  }

  .discoverMainSection {
    flex-direction: column;
    justify-content: center;
  }

  .discoverMainSection .coversImage {
    margin-top: 2rem;
    height: 30vh;
    width: auto;
  }
}

.active div {
  background-color: var(--blueberry);
}


.grid {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  justify-self: center;
}
