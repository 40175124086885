
// Use 't-shirt' sizing for breakpoints
$breakpoints: (
    'xs': (min-width: 0),
    'sm': (min-width: 576px),
    'md': (min-width: 599px),
    'lg': (min-width: 992px),
    'xl': (min-width: 1200px)
);

@mixin app-when($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query: if(type-of($raw-query)=='string',
            unquote($raw-query),
            inspect($raw-query));

        @media #{$query} {
            @content;
        }
    }

    @else {
        @error 'No value found for `#{$breakpoint}`. '
        +'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin app-blur-backdrop() {
  backdrop-filter: blur(4px);
  background-color: var(--app-color-background-transparent);
}

@mixin app-transition(
    $target: background,
    $duration: 0.2s,
    $timing: cubic-bezier(.29, .09, .24, .99)) {

    transition: $target $duration $timing;

}

