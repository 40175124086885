/* Button Reset */
.button {
  align-items: center;
  /* background: var(--white); */
  border: none;
  border-radius: 3rem;
  color: var(--white);
  cursor: pointer;
  display: inline-flex;
  font-family: MorrisonsAgenda;
  font-size: var(--font-size-body);
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  line-height: var(--line-height-body);
  outline: none;
  padding: 0 1.5rem;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  user-select: none;
}

.button:hover {
  filter: brightness(96%);
}

.button:disabled {
  opacity: 0.5;
}

.button:disabled:hover {
  cursor: default;
  filter: none;
}

.button > * {
  pointer-events: none;
}

/* Sizes */

.small {
  border-radius: 2.5rem;
  font-size: var(--font-size-body-sm);
  height: 2.5rem;
  line-height: var(--line-height-body-sm);
  padding: 0 1.25rem;
}

/* Types */

/* Icon Button */
.icon {
  padding: 0.5rem;
}

.icon img {
  border-radius: 100%;
  width: 2rem;
}

.iconBefore, .iconAfter {
  display: flex;
}

.iconBefore.small {
  padding: 0 1.25rem 0 0.75rem;
}

.iconBefore svg {
  margin-right: 0.75rem;
}

.iconAfter svg {
  margin-left: 0.75rem;
}

.iconBefore.small svg {
  margin-right: 0.625rem;
}

.iconAfter.small svg {
  margin-left: 0.625rem;
}


/* Styles */

/* Primary */
.primary {
  background: var(--primary);
  color: var(--white);
}

/* Secondary */
.secondary {
  background: var(--fresh-yellow);
  color: var(--black);
}

/* Tertiary */
.tertiary {
  background: var(--blueberry);
  color: var(--white);
}

/* Light */
.light {
  background: var(--warm-grey-25);
  color: var(--warm-grey-700);
}

/* Link */
.link {
  background: transparent;
  color: var(--tertiary);
}

/* error */
.error {
  background: var(--strawberry);
  color: var(--white);
}

.error-light {
  background: var(--strawberry-100);
  color: var(--strawberry);
}

.success {
  background: var(--fresh-green);
  color: var(--white);
}

/* Elevation */
.button[data-elevation="2-4"] {
  box-shadow: var(--elevation-2-4);
}
.button[data-elevation="4-4"] {
  box-shadow: var(--elevation-4-4);
}
.button[data-elevation="8-4"] {
  box-shadow: var(--elevation-8-4);
}
.button[data-elevation="16-4"] {
  box-shadow: var(--elevation-16-4);
}
.button[data-elevation="32-4"] {
  box-shadow: var(--elevation-32-4);
}

.button[data-elevation="2-8"] {
  box-shadow: var(--elevation-2-8);
}
.button[data-elevation="4-8"] {
  box-shadow: var(--elevation-4-8);
}
.button[data-elevation="8-8"] {
  box-shadow: var(--elevation-8-8);
}
.button[data-elevation="16-8"] {
  box-shadow: var(--elevation-16-8);
}
.button[data-elevation="32-8"] {
  box-shadow: var(--elevation-32-8);
}

.btn {
  color: white;
  background-color: #1762A7;
  padding: .75rem 1.5rem;
  border-radius: .5rem;
  text-decoration: none;
  font-size: .9rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #2180d8;
}

.btn:focus {
  background-color: #2796ff;
}