.container {
  align-items: center;
  background: black;
  box-shadow: var(--elevation-2-4);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 4rem;
}

.content {
  display: flex;
  grid-template-columns: 92px 1fr;
  justify-content: space-between;
  align-items: center;
}

.brandlLogo {
  display: flex;
  align-items: center;
}

.brandlLogoName {
  color: white;
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
