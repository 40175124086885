.logoutTimer {
  align-items: center;
  background: var(--strawberry);
  border-radius: 2rem;
  color: var(--white);
  display: grid;
  position: sticky;
  font-family: 'Oxygen Mono', monospace;
  font-size: var(--font-size-small-print);
  line-height: var(--line-height-small-print);
  justify-content: center;
  padding: 0.375rem 0.75rem;
  min-width: 5.75rem;
}

.main {
  position: sticky;
  align-items: center;
  display: grid;
  justify-content: center;
  padding: 0.25rem 0;
  bottom: 0;
}
