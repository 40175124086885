.listItem {
  align-items: center;
  background: transparent;
  border: none;
  color: var(--warm-grey);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  text-transform: capitalize;
  outline: none;
  width: 100%;
}

button.listItem {
  display: flex;
}

.listItem_sm {
  padding: 0.625rem 1rem;
}

.listItem:after {
  background: var(--warm-grey-50);
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

a.listItem:hover {
  background: var(--warm-grey-25);
}

.listItem[data-border-inset="1"]:after {
  left: 1rem;
  width: calc(100% - 1rem);
}

.listItem:last-child:after {
  display: none;
}

button.listItem {
  cursor: pointer;
}

button.listItem:disabled {
  cursor: auto;
}

.listItem > * {
  pointer-events: none;
}

.listItem strong {
  color: var(--warm-grey-700);
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listItem span {
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}