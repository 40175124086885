.tabSet {
  width: 100%;
}

.tabSet__tabs {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  justify-content: space-around;
  display: flex;
}

.tabSet__tabs_center {
  display: flex;
  justify-content: center;
}

.tabSet__tabs__border {
  background: var(--warm-grey-25);
  border-radius: 0.25rem;
  top: -0.25rem;
  display: block;
  height: 0.25rem;
  position: relative;
  width: 100%;
  z-index: 0;
}

.tabSet__tabs__border.warm-grey-100 {
  background: var(--warm-grey-100);
}

.tabSet__tabs ul {
  display: grid;
  grid-auto-flow: column;  
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.tabSet__tabs ul::-webkit-scrollbar {
  display: none;
}