.exception__actions__popOver {
  position: absolute;
  top: 30px;
  right: 20px;
}

.exception__actions__popOver > div {
  background: var(--white);
  border-radius: 0.5rem;
  bottom: -1.125rem;
  box-shadow: var(--elevation-4-4);
  height: 0;
  overflow: hidden;
  position: absolute;
  right: calc(100% + 0.25rem);
  visibility: hidden;
  width: 10rem;
  z-index: 2;
}


.exception__actions__popOver_active > div {
  height: auto;
  visibility: visible;
}

@media only screen and (min-width: 1024px) {
  .exception__actions__popOver > div {
    bottom: auto;
    top: -0.5rem;
  }
}

.exception__actions__popOver__info header {
  background: var(--fresh-green);
  border-radius: 0.5rem 0.5rem 0 0;
  color: var(--white);
  padding: 1rem;
  margin-bottom: 0.25rem;
}

.exception__actions__popOver__info__list {
  padding: 0.5rem 0;
}