.toggle {
  background: var(--warm-grey-50);
  border-radius: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
  width: 4.5rem;
}

.toggle_active {
  background: var(--fresh-green);
}

.toggle__ball {
  background: var(--white);
  border-radius: 2rem;
  box-shadow: var(--elevation-2-4);
  height: 2rem;
  left: 0;
  position: relative;
  transition: left 0.2s ease;
  width: 2rem;
}

.toggle_active .toggle__ball {
  left: 2rem;
}